<template>
  <div>
    <va-sidebar
      :width="width"
      :minimized="minimized"
      :minimizedWidth="minimizedWidth"
    >
      <menu-minimized v-if="minimized" :items="items" />
      <menu-accordion v-else :items="items" />
    </va-sidebar>
    <div v-html="c"></div>
  </div>
</template>

<script>
import { LoginInfo } from "@/class/login";
import { useGlobalConfig } from "vuestic-ui";
import MenuAccordion from "./menu/MenuAccordion.vue";
import MenuMinimized from "./menu/MenuMinimized.vue";
import NavigationRoutes from "./NavigationRoutes";
import NavigationRoutesAdm from "./NavigationRoutesAmbienteEscolar";
import NavigationRoutesCoordenacaoPed from "./NavigationRoutes";
import { Calendario } from "@/class/calendario";
import { Configuracao } from "@/class/configuracao";

export default {
  name: "app-sidebar",
  components: {
    MenuAccordion,
    MenuMinimized,
  },
  props: {
    width: { type: String, default: "16rem" },
    color: { type: String, default: "secondary" },
    minimized: { type: Boolean, required: true },
    minimizedWidth: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      funcaoDoUsuario: "",
      timer: "",
      verificardorAnoLetivo: true,
      items: [],
      routes: [],
      c: "",
    };
  },
  computed: {
    computedClass() {
      return {
        "app-sidebar--minimized": this.minimized,
      };
    },
    colors() {
      return useGlobalConfig().getGlobalConfig().colors;
    },
  },
  methods: {
    /*async verificar() {
      let data = await Calendario.calendarioAtual();
      if (data.data['error'] == "Não encontrado!"){
        this.verificardorAnoLetivo = false;
      }else{

        let dt = await Configuracao.obtemOne(data.data.id);
        if(dt.data.id){
          if(dt.data.autorizado == 1){
            this.$router.push({ name: 'painel' });
          }else{
            this.verificardorAnoLetivo = false;
          }
        }else{
          this.verificardorAnoLetivo = false;
        }
      }
    },*/
    montarRotas(permisoes) {
      if (this.verificardorAnoLetivo) {
        this.routes = [
          {
            name: "painel",
            displayName: "Painel Administrativo",
            meta: {
              icon: "vuestic-iconset-dashboard",
            },
          },
        ];

        for (const el of permisoes) {
          if (el.modulo == "Calendário" || el.modulo == "Calendario") {
            const novo = {
              name: "calendario",
              displayName: "Calendário Letivo",
              meta: {
                icon: "vuestic-iconset-dashboard",
              },
            };
            this.routes.push(novo);
          }
        }

        const educ = {
          name: "educacao",
          displayName: "Educação",
          meta: {
            icon: "vuestic-iconset-statistics",
          },
          children: [],
        };

        const confg = {
          name: "configuracao",
          displayName: "Configuração",
          meta: {
            icon: "vuestic-iconset-settings",
          },
          children: [],
        };

        const children = [];
        for (const el of permisoes) {
          if (el.modulo == "Escolas") {
            const novo = {
              name: "escolas",
              displayName: "Escolas",
            };
            children.push(novo);
          }
          if (el.modulo == "Segmento") {
            const novo = {
              name: "segmentos",
              displayName: "Segmentos",
            };
            children.push(novo);
          }
          if (el.modulo == "Períodos") {
            const novo = {
              name: "series",
              displayName: "Períodos",
            };
            children.push(novo);
          }
          if (el.modulo == "Disciplina") {
            const novo = {
              name: "disciplinas",
              displayName: "Disciplinas",
            };
            children.push(novo);
          }
          if (el.modulo == "Servidores") {
            const novo = {
              name: "servidores",
              displayName: "Servidores",
            };
            children.push(novo);
          }
          if (el.modulo == "Alunos") {
            const novo = {
              name: "alunosAll",
              displayName: "Alunos",
            };
            children.push(novo);
          }
          if (el.modulo == "Turmas") {
            const novo = {
              name: "turmas",
              displayName: "Turmas",
            };
            children.push(novo);
          }
          if (el.modulo == "Presenca - Gestão") {
            const novo = {
              name: "presencaAll",
              displayName: "Presença dos alunos",
            };
            children.push(novo);
          }
          if (el.modulo == "Notas - Gestão") {
            const novo = {
              name: "notasAll",
              displayName: "Notas das Turmas",
            };
            children.push(novo);
          }
          if (el.modulo == "Notas - Gestão") {
            const novo = {
              name: "conteudo-aplicado-gestor",
              displayName: "Conteudo Aplicado",
            };
            children.push(novo);
          }

          if (el.modulo == "Transferencia") {
            const novo = {
              name: "gestor/transferencia",
              displayName: "Solicitações de Transferência",
            };
            children.push(novo);
          }

          if (el.modulo == "Notas - Gestão") {
            const novo = {
              name: "educ-infantil-adm",
              displayName: "Perguntas Educ Infantil",
            };
            children.push(novo);
          }
        }

        const children2 = [];
        for (const el of permisoes) {
          if (el.modulo == "Automatizar Disciplinas") {
            let novo = {
              name: "automatizar",
              displayName: "Disciplinas",
            };
            children2.push(novo);

            novo = {
              name: "logo",
              displayName: "Logos",
            };
            children2.push(novo);

            novo = {
              name: "configuracao-sistema",
              displayName: "Sistema",
            };
            children2.push(novo);

            novo = {
              name: "dashboard-relatorio",
              displayName: "Relatorio",
            };
            children2.push(novo);
          }
        }

        educ.children = children;
        confg.children = children2;
        this.routes.push(educ);
        if (children2.length > 0) {
          this.routes.push(confg);
        }
      } else {
        this.routes = [
          {
            name: "painel",
            displayName: "Painel Administrativo",
            meta: {
              icon: "vuestic-iconset-dashboard",
            },
          },
        ];

        for (const el of permisoes) {
          if (el.modulo == "Calendário" || el.modulo == "Calendario") {
            const novo = {
              name: "calendario",
              displayName: "Calendário Letivo",
              meta: {
                icon: "vuestic-iconset-dashboard",
              },
            };
            this.routes.push(novo);
          }
        }

        const educ = {
          name: "educacao",
          displayName: "Educação",
          meta: {
            icon: "vuestic-iconset-statistics",
          },
          children: [],
        };

        const confg = {
          name: "configuracao",
          displayName: "Configuração",
          meta: {
            icon: "vuestic-iconset-settings",
          },
          children: [],
        };

        const children = [];
        for (const el of permisoes) {
          if (el.modulo == "Escolas") {
            const novo = {
              name: "escolas",
              displayName: "Escolas",
            };
            children.push(novo);
          }
          if (el.modulo == "Segmento") {
            const novo = {
              name: "segmentos",
              displayName: "Segmentos",
            };
            children.push(novo);
          }
          if (el.modulo == "Períodos") {
            const novo = {
              name: "series",
              displayName: "Períodos",
            };
            children.push(novo);
          }
          if (el.modulo == "Disciplina") {
            const novo = {
              name: "disciplinas",
              displayName: "Disciplinas",
            };
            children.push(novo);
          }
          if (el.modulo == "Servidores") {
            const novo = {
              name: "servidores",
              displayName: "Servidores",
            };
            children.push(novo);
          }
          if (el.modulo == "Alunos") {
            const novo = {
              name: "alunosAll",
              displayName: "Alunos",
            };
            children.push(novo);
          }
          if (el.modulo == "Turmas") {
            const novo = {
              name: "turmas",
              displayName: "Turmas",
            };
            children.push(novo);
          }
        }

        const children2 = [];

        for (const el of permisoes) {
          if (el.modulo == "Automatizar Disciplinas") {
            let novo = {
              name: "automatizar",
              displayName: "Disciplinas",
            };
            children2.push(novo);

            novo = {
              name: "logo",
              displayName: "Logos",
            };
            children2.push(novo);

            novo = {
              name: "configuracao-sistema",
              displayName: "Sistema",
            };
            children2.push(novo);

            novo = {
              name: "dashboard-relatorio",
              displayName: "Relatorio",
            };
            children2.push(novo);
          }
        }

        educ.children = children;
        confg.children = children2;
        this.routes.push(educ);
        if (children2.length > 0) {
          this.routes.push(confg);
        }
      }
    },
    async verificarSeTemCalendarioAFechar() {
      const data = await Calendario.verificarSeExistemCalendarioAFechar();
      if (!data.data.ok) {
        this.$vaToast.init({
          message: "Existe calendários que precisam ser fechados!",
          iconClass: "fa-star-o",
          position: "top-left",
          duration: 3500,
          fullWidth: false,
          color: "warning",
        });
      }
    },
  },
  async beforeMount() {
    const colorBar = sessionStorage.getItem("colorBar");
    const colorBar_text = sessionStorage.getItem("colorBar_text");
    const colorDiaLetivo = sessionStorage.getItem("colorDiaLetivo");
    if (colorBar) {
      this.c =
        "<style>.va-sidebar{background-color: " +
        colorBar +
        " !important; color: " +
        colorBar_text +
        " !important } .app-layout__sidebar-wrapper{background-color: " +
        colorBar +
        " !important;}  .color_letivo {background-color: " +
        colorDiaLetivo +
        " !important}   </style>";
    }

    const token = sessionStorage.getItem("token");
    this.funcaoDoUsuario = sessionStorage.getItem("funcaoDoUsuario");

    const whoiam = await LoginInfo.WhoIam(token);

    if (whoiam.data.funcao == 1) {
      this.items = NavigationRoutes.routes;
    } else if (whoiam.data.funcao == 2) {
      this.montarRotas(whoiam.data.permissao);
      this.items = this.routes;
    } else if (whoiam.data.funcao == 7) {
      this.montarRotas(whoiam.data.permissao);
      this.items = this.routes;
    }
    if (this.funcaoDoUsuario == 2)
      {this.timer = setInterval(
        () => this.verificarSeTemCalendarioAFechar(),
        60 * 60 * 1000
      );}
  },
  Unmounted() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.va-sidebar {
  .va-collapse__body {
    margin-top: 0 !important;
  }

  &__menu {
    padding: 2rem 0;
    &__inner {
      padding-bottom: 8rem;
    }
  }

  &-item {
    &-content {
      padding: 0.75rem 1rem;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.va-sidebar {
  flex-shrink: 0;
}

// .va-sidebar--minimized {
//   width: auto !important;
// }
</style>
