import axios from 'axios'

export class LoginInfo {
  static async Logar(info, senha) {
    try {
      sessionStorage.removeItem("token");
      const res = await axios.post('/auth', {
        login: info,
        password: senha,
      });
      sessionStorage.setItem("token", res.data.token);
      return 1;
    }
    catch (e) {
      return 0;
    }
  }
  static async createLoginResponsavel(info) {
    if (info === null || info === undefined) {
      throw new Error("Invalid info");
    }
    try {
      const res = await axios.post('/auth/criar/login/responsavel', info);
      return res;
    }
    catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      else {
        // Should not happen, but just in case.
        throw new Error("Unknown error");
      }
    }
  }
  static async updateLoginResponsavel(info, id) {
    if (info === null || info === undefined) {
      throw new Error("Invalid info");
    }
    try {
      const res = await axios.post('/auth/editar/login/responsavel/' + id, info);
      return res;
    }
    catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      else {
        // Should not happen, but just in case.
        throw new Error("Unknown error");
      }
    }
  }
  static async getLoginByCPF(cpf) {
    try {
      if (typeof cpf === 'undefined' || cpf === null) {
        throw new Error("Invalid cpf");
      }
      const res = await axios.get('/auth/getuser/cpf/' + encodeURIComponent(cpf), {
      });
      return res.data;
    } catch (e) {
      if (e.response && e.response.status === 404) {
        return e.response.data;
      } else {
        console.error(e);
        throw e;
      }
    }
  }
  static async meuLogin() {
    return axios.post('/auth/me')
  }
  static async logout() {
    return axios.get('/auth/logout')
  }

  static async WhoIam(token, ano) {
    try {
      if (ano == undefined) {
        ano = sessionStorage.getItem("anoSelecionado");
      }
      return axios.get('/whoiam/ano/'+ano, {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        //cache
        headers: {
          'cache-control': 'max-age=3600',
        }
      });

    } catch (e) {
      return e;
    }
  }

  static async trocarSenhaPerfil(playload) {
    try {
      return axios.post('/trocasenha', playload);

    } catch (e) {
      return e;
    }
  }

  static async enviarEmailEsqueceuSenha(playload) {
    try {
      return axios.post('/esqueceu-senha', playload);

    } catch (e) {
      return e;
    }
  }

  static async trocarSenhaNoEsqueceuSenha(playload) {
    try {
      return axios.post('/nova-senha', playload);
    } catch (e) {
      return e;
    }
  }
  static async sendEmailHtml(playload) {
    try {
      return axios.post('/sendemail/token', playload);
    } catch (e) {
      return e;
    }
  }
}
